import electronLogo from './assets/electron.svg'
import { PythonTrigger } from './components/python-trigger.tsx'
import './App.css';

import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react'
function App() {
   
  return (
    <div className="App">
      <img alt="logo" className="logo" src={electronLogo} />
      <div className="creator">Powered by electron-vite</div>
      <div>login</div>
      <div>Web APP</div>
      <div>
        {' '}
        <SignedOut>
          <SignInButton />
        </SignedOut>
        <SignedIn>
          <UserButton />
        </SignedIn>
      </div>
      <PythonTrigger />
     
    
      
    </div>
  );
}

export default App;
