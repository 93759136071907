import React from 'react';
import { useAuth } from '@clerk/clerk-react';

export function PythonTrigger(): JSX.Element {
  const { getToken } = useAuth();

  const ipcHandle = async (): Promise<void> => {
    const token = await getToken();
    console.log(token)
  
  };

  return (
    <div>
      <button onClick={ipcHandle}>Trigger Python</button>
    </div>
  );
}
