import React from 'react';
import { createRoot } from 'react-dom/client';
import { ClerkProvider } from '@clerk/clerk-react';
import App from './App';
import './assets/main.css'
// Access environment variable
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

console.log('Clerk Publishable Key:', PUBLISHABLE_KEY); // Verify it's correctly loaded

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
    <App />
  </ClerkProvider>
);
